@import "~antd/dist/antd.css";

:root {
  --primary: #6502bc;
  --secondary: #FF8C3E;
}

.ant-layout-sider-zero-width-trigger {
  top: 0px;
  background-color: var(--primary);
}
.ant-layout-sider-zero-width-trigger:hover {
  background-color: var(--primary);
}

.ant-layout-sider {
  background-color: var(--primary);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--primary);
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: rgba(0, 0, 0, 0);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(237, 116, 46, 0.2);
}

.Menu {
  background-color: var(--primary);
}

.logo {
  width: 80px;
  height: 80px;
}

.logoContainer {
  text-align: center;
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
}
