
.custom-pointers .react-colorful__saturation-pointer,
.custom-pointers .react-colorful__hue-pointer,
.custom-pointers .react-colorful__alpha-pointer {
    width: 16px;
    height: 16px;
  }
  
  /* Custom layout */
  .custom-layout .react-colorful {
    padding: 0;
    background: #33333a;
    border-radius: 0;
  }
  
  .custom-layout .react-colorful__saturation {
    margin: 0px 0;
    height: 30px;
    border-bottom: 0;
    border-radius: 0;
  }
  
  .custom-layout .react-colorful__hue {
    order: -1;
  }
  
  .custom-layout .react-colorful__hue,
  .custom-layout .react-colorful__alpha {
    height: 12px;
  }
  
  .custom-layout .react-colorful__hue-pointer,
  .custom-layout .react-colorful__alpha-pointer {
    width: 12px;
    height: 12px;
  }
  
  .custom-layout .react-react-colorful__interactive {
    height: 12px;
  }
  
  .custom-layout .react-colorful__last-control {
    height: 12px;
    border-radius: 0;

  }
  
  .custom-layout .react-colorful__hue .react-colorful__last-control {
    height: 12px;
  }
  