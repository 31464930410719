.hide {
  transform: translate(0, 0px);
}

.show {
  transform: translate(calc(100% - 40px), 0px);
}

.alignCenter {
  max-width: 50%;
  padding-right: 20px;
}


.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.wordTextArea {
  resize: "none";
  width: "100%" !important;
  height: "100%";
  margin: "10px";
}

.loadingContainer {
  text-align: center;
}

.loading {
  width: 80px;
  height: 80px;
}

.hidden {
  display: none;
}