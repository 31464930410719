@import "../app/App.css";

.loginContainer {
  background: var(--primary);
  width: 100%;
  height: 100vh;
}

.loginLogoContainer {
  text-align: center;
  display: block;
  margin-bottom: 50px;
}

.loginCenter {
  background: #fafafa;
  padding: 50px 40px 40px 40px;
  position: fixed;
  border-radius: 5px;
  left: 50%;
  top: 50%;
  min-width: 380px;
  max-width: 380px;
  transform: translate(-50%, -50%);

}

.login-form {
  max-width: 300px;
}

.login-form-button {
  width: 100%;
}