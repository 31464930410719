.contentWrapper {
  height: 100vh;
  overflow: scroll;
  padding-bottom: 40px;
}

.userDashboardTabs .ant-tabs-nav .ant-tabs-tab {
  color: #6502bc !important;
}

.userDashboardTabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #6502bc !important;
}

.userDashboardTabs .ant-tabs-nav .ant-tabs-ink-bar {
  color: #6502bc !important;
  background-color: #6502bc !important;
}