@import url("../app/App.css");

.disable-text {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.editor {
  font-size: 11pt;
  display: flex;
  padding: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: 100%;
}

.editorGutterBackground {
  height: 100%;
  width: 210px;
  position: absolute;
  top: 0;
  background: #d9d9d9;
}

.editorBackground {
  width: 590px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #ffffff;
}

.editorOverlay {
  z-index: 9;
  /* margin: 30px; */
  background: #009938;
}

.textEditorTextArea {
  overflow-y: scroll;
  z-index: 9;

  /* background-color: rgba(255, 255, 255, 1); */
  /* padding: 12px; */
  /* width: 50%; */
  /* margin: auto; */
  /* border-radius: 6px; */
}


.normalStyle {
  line-height: 1.5;
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 11pt;
  color: #6502bc;
  /* display: inline; */
  /* background-color: rgba(0, 72, 255, 0.057); */
}

.markedStyle {
  line-height: 1.5;
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 11pt;
  display: inline;
  background-color: rgba(101, 2, 188, 0.05);
}


.edition {
  line-height: 1.5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-size: 11pt;
  color: rgb(50, 50, 50);
  /* display: inline; */
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 10px;
  padding-bottom: 1px;
  margin-left: -6px
}

.edition:focus {
  outline: 1px solid PUDU_SECONDARY_COLOUR;
}


.edition:empty {
  content: attr(data-placeholder);
}

.edition:not(:empty):before {
  content: attr(data-placeholder);
  /* background-color: rgba(59, 83, 202, 0.3); */
}

.edition:not(:empty):after {
  content: attr(data-placeholder);
  /* background-color: rgba(59, 83, 202, 0.3); */
}

.saved {
  display: none;
}

.unsaved {
  font-style: italic;
  font-size: 9pt;
  /* margin-left: 4pt; */
  background-color: rgba(255, 0, 0, 0.527) !important;
  display: inline;
}

.saving {
  font-style: italic;
  font-size: 9pt;
  /* margin-left: 4pt; */
  color: #6502bc !important;
  display: inline;
}

.editionOptions {
  background: rgba(164, 164, 164, 0.776);
  min-width: 50px;
  max-width: 50px;
  /* min-height: 24px; */
  /* max-height: 24px; */
  transform: translate(-50%, -90%);
  position: absolute;
  /* overflow: visible; */
  display: inline;
  border-radius: 4px;
  padding: 3px;
  z-index: 50;
}

.activeWord {
  background-color: rgba(255, 139, 62, 0.101);
  /* text-decoration: underline; */
}

.cursor {
  background: rgb(255, 0, 0);
  min-width: 1px;
  max-width: 1px;
  min-height: 14px;
  max-height: 14px;
  overflow: visible;
  display: inline;
}

.saveCircleButton {
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  background: rgb(181, 15, 15);
  border-radius: 50%;
  display: inline-block;
  /* margin: 2px; */
  z-index: 100;
}

.saveCircleButton.right {
  background: rgb(0, 255, 106);
  float: right;
  z-index: 100;
}